import React, { useEffect } from "react";
import { useLocation } from "react-router";

type LocationProps = {
	onChange: (location: Location) => void;
};

const Location = ({ onChange }: LocationProps) => {
	const location: any = useLocation();

	useEffect(() => {
		onChange(location);
	}, [location]);
	return <></>;
};

export default Location;
